import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const DiscografiaEsPrimavera = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Es Primavera - 2015"
        titleTemplate="%s | Eduardo Ceballos"
        description="Es Primavera - 2015"
        canonical="https://eduardoceballos.com/discografia/es-primavera"
        openGraph={{
          url: `https://eduardoceballos.com/discografia/es-primavera`,
          title: `Es Primavera - 2015`,
          description: `Es Primavera - 2015`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/discografia.jpg`,
              alt: `Es Primavera - 2015`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Es Primavera - 2015</h1>
      <center>
        <img
          className="discos-img"
          style={{ float: "Right", marginLeft: "10px" }}
          src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdesprimavera_490986f163.jpg"
          alt=""
        />
      </center>
      <div>
        <h6>Prólogo</h6>
        <p>
          Desde el mismo instante de la unión de la sangre, viene navegando en
          un anchuroso río de tiempos y memorias, traficando la palabra y los
          sueños.
        </p>
        <p>
          En un incesante construir armoniosamente la poesía, que siente desde
          el átomo más ínfimo de su cuerpo hasta el macro mundo del espacio
          sideral.
        </p>
        <p>
          En su voz revivimos la ternura del amor, la maravilla de los astros y
          caminamos por la historia que nos remonta a siglos de gloria, de
          hazañas, descubrimientos, artes y ciencias.
        </p>
        <p>
          Nos lleva a sentir los cambios de clima y a conectarnos con la
          naturaleza en una explosión de misterio.
        </p>
        <p>El amor está siempre presente como una forma mágica de</p>
        <p>salvar la tierra.</p>
        <p>
          Nos regala la ilusión de sentir con este libro, que los años no pasan,
          que la vida siempre será, porque hoy como ayer «Es primavera».
        </p>
        <p>
          Tomamos la semilla de lo verde y cosechamos del jardín de sus versos
          las fragancias de sus imágenes, para formar un ramillete de rosas que
          diga con el poeta:
        </p>
        <p>
          Eduardo Ceballos, en cada página suelta sus vibrantes energías, empuja
          los espacios, moviliza los ángulos. Toma vocablos encontrados en el
          diccionario del tiempo, hasta descubrir una palabra para silabear el
          silencio que mueva la sangre y emocione. Maneja tiempos y nostalgias.
          En su búsqueda trae la memoria de la tierra y de los astros.
        </p>
        <p>
          Nombra el maravilloso mundo circundante y con la alcancía de sus ojos,
          pinta sueños. Cruza los océanos, asombrando al viajero.
        </p>
        <p>
          Protagonista de un nuevo destino, camina lento entre tinieblas
          buscando la luz que ilumine el cerebro de los hombres.
        </p>
        <p>
          Los años le dieron alegrías y dolores que lo fueron modelando. Su
          mundo es mágico, vuela como un pájaro feliz, cruzando los cielos para
          dibujar el paisaje, en una visión irrepetible, donde la libertad es un
          himno.
        </p>
        <p>
          Formula la fiesta de la poesía y sus manos creadoras mueven la
          voluntad y la inteligencia en su gesto creativo.
        </p>
        <p>
          Guarda en el cofre de su memoria cada instante, como símbolo vital de
          su paso por la existencia.
        </p>
        <p>
          Humilde su canto, humilde su vida. Se desnuda ante el papel, para
          fundar un jardín de pensamientos vivos.
        </p>
        <p>
          Llora, canta, ríe en el remolino furioso de su tiempo, para aplacarse
          del todo cuando la tierra lo abrace definitivamente. Volverá con la
          primavera, con flores nuevas y levantará con sus
        </p>
        <p>trinos, cantos azules para el alma.</p>
        <p>
          Como una paloma, como una simple paloma, cruzará por el aire con su
          aleteo vivaz, como símbolo de paz.
        </p>
        <p>
          Llenará de luz y aroma, ofrecerá la suavidad de la flor, las acequias,
          cascadas, música de golondrinas y perfume de azahar.
        </p>
        <p>
          Vieja alquimia‘, piedra que rueda sin cesar, porque también en el río
          de su sangre «Es primavera».
        </p>
      </div>
      <div class="autores">
        <div class="autor">
          <h6>Susana Rozar</h6>
          {/* <img src="" alt="" /> */}
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default DiscografiaEsPrimavera
